import React, { useEffect, useState } from "react";
import CardCustom from "../components/CardCustom";
import { Carousel } from "flowbite-react";
import { Link } from "react-router-dom";
import CS from "../assets/cs.png";
import Dolar from "../assets/dolar.png";
import Apolo from "../assets/apolo.png";
// import { , products } from "../config/products";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, getProductsByCategory } from "../config/api/categoryCrud";
import ScrollAnimation from "react-animate-on-scroll";

function Home() {
  
  const dispatch = useDispatch();

  const {categories, isLoading, products} = useSelector(state => state.category)

  const [selectedCategory, setSelectedCategory] = useState(26);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getProductsByCategory({id : selectedCategory}))
  }, [dispatch]);

  return (
    <div className="md:px-20 w-full mx-auto ">
      {/* atas */}
      <div className="h-[190px] md:h-[600px] w-full relative">
        <img
          src={require("../assets/banner-homepage.png")}
          className="h-full object-cover w-full"
          alt="random"
        />
        <div className="absolute top-1/2 right-0 transform -translate-y-1/2 w-4/6 md:w-3/6 p-4 leading-none">
          <p className="tracking-tighter md:tracking-normal md:text-5xl font-bold text-center text-white font-inter">
            CUSTOM ANY JERSEY FOR ANY SPORT
          </p>
          <p className="text-xs tracking-tighter md:tracking-normal md:text-2xl text-center text-white font-inter pt-1 md:pt-4">
            Design - Print - Ship
          </p>
          <p className="text-xs md:text-2xl text-center text-white font-inter tracking-tighter md:tracking-normal leading-none">
            to every country, <br className="md:hidden -mt-1" /> wherever you are.
          </p>
          <div className="flex flex-row justify-between gap-5 md:gap-0 pt-2 items-center md:pt-4 md:w-3/4 mx-auto">
            <Link to="/design-your-own">
              <div className="flex justify-center items-center bg-oren px-0.5 md:px-4 md:py-1 rounded hover:cursor-pointer hover:scale-105">
                <h2 className="text-white text-xs font-inter md:text-xl md:font-semibold">
                  GET STARTED
                </h2>
              </div>
            </Link>
            <Link to="/product">
              <div className="flex flex-row md:justify-between items-center md:gap-2 hover:cursor-pointer hover:scale-105">
                <h3 className="text-white text-xs font-inter md:text-xl md:font-semibold">
                  EXPLORE PRODUCT
                </h3>
                <div className="flex items-center justify-center w-5 md:w-9 h-5 md:h-9 rounded-full border-2 md:border-4 border-oren">
                  <svg
                    className="w-4 md:w-6 h-4 md:h-6 text-oren"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* kategori */}
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut" duration={0.5}>
      <div className="w-full">
        <h1 className=" md:text-3xl font-bold text-white font-inter text-left md:mt-8 md:mb-4 mt-4 mb-2 uppercase">
          explore our catalogues
        </h1>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row md:gap-6 gap-1 w-full overflow-x-auto">
            {categories.map((category) => (
              <div
                key={category.id}
                onClick={() => {
                  setSelectedCategory(category.id)
                  dispatch(getProductsByCategory({id : category.id}))
                }}
                className={
                  "md:px-2 px-1 md:py-1 py-0.5 text-white font-inter rounded-sm md:text-xl hover:cursor-pointer hover:scale-105 uppercase " +
                  (selectedCategory === category.id ? "bg-oren" : "")
                }
              >
                {category.name}
              </div>
            ))}
          </div>
          <Link to="/product">
            <div className="flex text-sm md:text-xl items-center text-oren hover:cursor-pointer hover:scale-105">
              VIEW MORE
              <svg
                className="md:ml-2 h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </Link>
        </div>
        <div className="flex-row mt-4 gap-5 justify-between hidden md:flex">
          {products.slice(0, 3).map((item) => (
            <CardCustom key={item.id} item={item} />
          ))}
        </div>
        <div className="md:hidden mt-2 relative">
          <div className="px-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {products.slice(0, 3).map((item) => (
              <CardCustom key={item.id} item={item} />
            ))}
          </div>
        </div>
      </div>
      </ScrollAnimation>
      {/* banner */}
      <ScrollAnimation animateIn="fadeIn" delay={400} duration={0.5}>
      <div className="relative w-full h-[260px] md:h-[690px] my-10">
        <img
          src={require("../assets/cross_kanan.png")}
          className="absolute top-0 left-0 w-full h-full object-cover"
          alt="random"
        />
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2 w-1/3 p-4 ">
          <p className="text-4xl md:text-7xl font-bold text-left text-white font-inter">
            SHIP TO EVERY COUNTRY
          </p>
        </div>
      </div>
      </ScrollAnimation>
      {/* promis */}
      <ScrollAnimation animateIn="tada" delay={400} animateOut="fadeOut" duration={0.5}>
      <div className="w-full">
        <h1 className="text-white font-inter text-xl md:text-4xl text-center font-bold pt-5">
          WHAT YOU GET.
        </h1>
        <div className="flex flex-row px-2 md:px-16 md:mt-10 gap-10 mt-4">
          <div className="w-1/3 border-b-2 border-oren flex flex-col justify-center items-center">
            <div className="md:w-24 w-14 md:h-24 h-14 bg-[#282828] rounded-full flex items-center justify-center">
              <img src={Apolo} alt="" />
            </div>
            <h1 className="text-white font-inter md:text-3xl text-center font-semibold my-3 md:my-10">
              FAST <span className="text-oren">DELIVERY</span>{" "}
            </h1>
            <p className="text-white/60 font-inter text-sm md:text-lg text-center mb-5">
              Received your stuff faster
            </p>
          </div>
          <div className="w-1/3 border-b-2 border-oren flex flex-col justify-center items-center">
            <div className="md:w-24 w-14 md:h-24 h-14 bg-[#282828] rounded-full flex items-center justify-center">
              <img src={CS} alt="" />
            </div>
            <h1 className="text-white font-inter md:text-3xl text-center font-semibold my-3 md:my-10">
              24/7 CUSTOMER <span className="text-oren">SERVICE</span>{" "}
            </h1>
            <p className="text-white/60 font-inter md:text-lg text-sm text-center mb-5">
              Friendly 24/7 customer support
            </p>
          </div>
          <div className="w-1/3 border-b-2 border-oren flex flex-col justify-center items-center">
            <div className="md:w-24 w-14 md:h-24 h-14 bg-[#282828] rounded-full flex items-center justify-center">
              <img src={Dolar} alt="" />
            </div>
            <h1 className="text-white font-inter md:text-3xl text-center font-semibold my-3 md:my-10">
              BEST PRICE <span className="text-oren">BEST QUALITY</span>{" "}
            </h1>
            <p className="text-white/60 font-inter text-sm md:text-lg text-center mb-5">
              Get best price with best quality
            </p>
          </div>
        </div>
      </div>
      </ScrollAnimation>
      {/* partner */}
      <ScrollAnimation animateIn="bounce" delay={400} animateOut="fadeOut" duration={0.5}>
      <div className="w-full flex flex-col my-4 md:my-10 gap-5">
        <div className="flex flex-row justify-center items-center w-full">
          <h3 className="text-oren font-inter text-3xl md:text-6xl text-center font-semibold my-2 md:my-10">
            PROUD
          </h3>
          <img
            src={require("../assets/partner.png")}
            alt="hero"
            className="md:h-8 h-4 -ml-1 md:-ml-4"
          />
        </div>
      </div>
      </ScrollAnimation>
      {/* bawah */}
      <div className="hidden md:block md:h-[680px] w-11/12 mx-auto">
        <Carousel slideInterval={500}>
          {
            Array.from({ length: 5 }).map((_, index) => (
              <div  key={index} className="h-full mx-auto">
                <div className="h-full mx-auto flex flex-row w-11/12 gap-5">
                  <img src={require("../assets/images/real/"+(index+1)+".png")} alt="bg" className="h-full object-cover w-1/2" />
                  <img src={require("../assets/images/real/"+(index+1)+(index+1)+(index+1 === 4 ? '.png' : index+1 === 5 ? '.png' : '.jpg'))} alt="bg" className="h-full object-cover w-1/2" />
                </div>
              </div>
            ))
          }
        </Carousel>
      </div>
      {/* bawah */}
      <div className="md:hidden h-[420px] w-11/12 mx-auto">
        <Carousel slideInterval={500}>
          {
            Array.from({ length: 5 }).map((_, index) => (
              <div  key={index} className="h-full mx-auto">
                <div className="h-full mx-auto flex flex-row w-full gap-5">
                  <img src={require("../assets/images/real/"+(index+1)+".png")} alt="bg" className="h-full object-cover w-full" />
                </div>
              </div>
            ))
          }
        </Carousel>
      </div>
    </div>
  );
}

export default Home;
