import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CATEGORY, API_PRODUCT, API_PRODUCT_IMAGE } from "..";

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async () => {
    try {
      const response = await axios.get(API_CATEGORY);
      return response.data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
);

export const getProducts = createAsyncThunk("product/getProducts", async () => {
  try {
    const response = await axios.get(API_PRODUCT);
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
});

export const getProductsByCategory = createAsyncThunk(
  "product/getProductsByCategory",
  async ({ id }) => {
    try {
      const response = await axios.get(
        `${API_PRODUCT}/category/${id}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
);

export const getProductById = createAsyncThunk(
  "product/getProductById",
  async ({ id }) => {
    try {
      const response = await axios.get(`${API_PRODUCT}/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
);

export const getProductImages = createAsyncThunk(
  "product/getProductImages",
  async ({ id }) => {
    try {
      const response = await axios.get(`${API_PRODUCT_IMAGE}/product/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
      if(error.response.status === 404){
        return []
      }
      return Promise.reject(error);
    }
  }
);