import React, { useEffect, useState } from 'react'
import CardProduct from '../components/CardProduct'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories, getProducts, getProductsByCategory } from '../config/api/categoryCrud'

function Product() {

    const dispatch = useDispatch()
    
    const [selectedCategory, setSelectedCategory] = useState(localStorage.getItem('cate') || 26)

    const {categories, products, isLoading} = useSelector(state => state.category)

    const handleChange = (item) => {
        setSelectedCategory(item)
        localStorage.setItem('cate', item)
        dispatch(getProductsByCategory({id : item}))
    }

    useEffect(() => {
        dispatch(getCategories())
        dispatch(getProductsByCategory({id : selectedCategory}))
        const cate = localStorage.getItem('cate')
        setSelectedCategory(parseInt(cate))
    }, [dispatch])

    return (
        <div className="md:px-20 w-full mx-auto">
            <div className="w-full flex flex-row justify-between items-center">
                <h1 className="text-2xl md:text-5xl font-bold text-left text-white font-inter">Product<span className="text-gray-500 text-sm md:text-base"> {29} products</span></h1>
            </div>
            <div className="flex flex-row gap-1 md:gap-6 mt-2 overflow-x-auto md:mt-5">
                {categories.map((item, index) => ( 
                    <div key={index} onClick={() => handleChange(item.id)} className={"md:px-2 px-1 py-1 text-white font-inter rounded-sm text-lg md:text-xl hover:cursor-pointer hover:scale-105 uppercase " + (selectedCategory === item?.id ? 'bg-oren' : '')}>{item.name}</div>
                ))}
            </div>
            <div className="w-full py-2 md:py-8 grid grid-col md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 md:gap-4">
                {/* card */}
                { isLoading ? (
                    <div className="w-full flex flex-row justify-center items-center absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50">
                        <div className="w-8 h-8 border-t-2 border-b-2 border-oren rounded-full animate-spin"></div>
                    </div>
                ) : products.map((item, index) => (
                    // <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' key={index} >
                        <CardProduct key={index} item={item} />
                    // </ScrollAnimation>
                ))}
            </div>
        </div>
    )
}

export default Product