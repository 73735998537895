import { createSlice } from "@reduxjs/toolkit"
import { getCategories, getProductById, getProductImages, getProducts, getProductsByCategory } from "../api/categoryCrud";


const categorySlice = createSlice({
    name: "category",
    initialState: {
        categories: [],
        products : [],
        product : {},
        images : [],
        isLoading : false,
    },
    extraReducers : (builder) => {
        builder
          .addCase(getCategories.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getCategories.fulfilled, (state, action) => {
            state.isLoading = false;
            state.categories = action.payload.data.categories.data;
          })
          .addCase(getCategories.rejected, (state) => {
            state.isLoading = false;
          })
          .addCase(getProducts.fulfilled, (state, action) => {
            state.isLoading = false;
            state.products = action.payload.data;
          })
          .addCase(getProducts.rejected, (state) => {
            state.isLoading = false;
          })
          .addCase(getProducts.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getProductsByCategory.fulfilled, (state, action) => {
            state.isLoading = false;
            state.products = action.payload.data;
          })
          .addCase(getProductsByCategory.rejected, (state) => {
            state.isLoading = false;
          })
          .addCase(getProductsByCategory.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getProductById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.product = action.payload.data;
          })
          .addCase(getProductById.rejected, (state) => {
            state.isLoading = false;
          })
          .addCase(getProductById.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getProductImages.fulfilled, (state, action) => {
            state.isLoading = false;
            state.images = action.payload.data;
          })
          .addCase(getProductImages.rejected, (state) => {
            state.isLoading = false;
          })
          .addCase(getProductImages.pending, (state) => {
            state.isLoading = true;
          })
      }
})

export default categorySlice.reducer