const BASE_URL = process.env.REACT_APP_API_URL_DEV
const BASE_URL_PROD = process.env.REACT_APP_API_URL_PROD

export const API_DYO = BASE_URL + '/dyos';
export const API_CHECKOUT = BASE_URL + '/checkout';
export const API_WHOLESALE = BASE_URL + '/wholesales';
export const API_ORDER = BASE_URL + '/orders';
export const API_CONFIRM_PAYMENT = BASE_URL + '/payment-approval';
export const API_MSG = BASE_URL + '/messages-posts';

export const API_CATEGORY = BASE_URL_PROD + '/categories';
export const API_PRODUCT = BASE_URL_PROD + '/products';
export const API_PRODUCT_IMAGE = BASE_URL_PROD + '/product-images';

export const STATIC_FILE = "https://api-admin.jipperdesign.com/public/storage/"