import React, { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Down from "../assets/down.svg";
import Cart from "../assets/cart.svg";
import Logo from "../assets/logo.png";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "flowbite-react";

function Header() {
  const [navBgColor, setNavBgColor] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const location = useLocation();

  const cart = JSON.parse(localStorage.getItem("cart") || "[]");

  const changeNavBgColor = () => {
    if (window.scrollY >= 50) {
      setNavBgColor(true);
    } else {
      setNavBgColor(false);
    }
  };

  const subtotal = useMemo(() => {
    return cart.reduce((acc, item) => {
      return acc + item.price * item.quantity
    }, 0)
  }, [cart])

  window.addEventListener("scroll", changeNavBgColor);

  return (
    <div
      className={
        "flex mx-auto h-10 md:h-20 sticky top-0 z-50 " +
        (navBgColor ? "bg-black" : "")
      }
    >
      <div className="hidden w-full md:flex flex-row justify-between items-center px-10">
        <Link to="/">
          <img src={Logo} alt="" className="h-12 w-12" />
        </Link>
        <div className="flex flex-row w-full justify-end items-center gap-7 font-inter">
          <Link
            to="/"
            className={
              "text-xl hover:cursor-pointer hover:scale-105 text-white " +
              (location.pathname === "/"
                ? "px-4 py-1 border-oren border-b-2"
                : "")
            }
          >
            HOME
          </Link>
          <Link
            to="/product"
            className={
              "text-xl hover:cursor-pointer hover:scale-105 text-white " +
              (location.pathname === "/product"
                ? "px-4 py-1 border-oren border-b-2"
                : "")
            }
          >
            PRODUCT
          </Link>
          <Link
            to="/design-your-own"
            className={
              "text-xl hover:cursor-pointer hover:scale-105 text-white rounded-sm px-2 py-1 " +
              (location.pathname === "/design-your-own"
                ? "px-4 py-1 border-oren border-b-2"
                : "")
            }
          >
            DESIGN YOUR OWN
          </Link>
          <Link
            to="/wholesale"
            className={
              "text-xl hover:cursor-pointer hover:scale-105 text-white " +
              (location.pathname === "/wholesale"
                ? "px-4 py-1 border-oren border-b-2"
                : "")
            }
          >
            WHOLESALE
          </Link>
          <Link
            to="/order"
            className={
              "text-xl hover:cursor-pointer hover:scale-105 text-white " +
              (location.pathname === "/order"
                ? "px-4 py-1 border-oren border-b-2"
                : "")
            }
          >
            ORDER FORM
          </Link>
          <Link
            to="/info"
            onMouseEnter={() => setShowInfo(true)}
            onMouseLeave={() => setShowInfo(false)}
            className={
              "text-xl hover:cursor-pointer text-white flex flex-row items-center gap-1 relative " +
              (location.pathname === "/info"
                ? "px-4 py-1 border-oren border-b-2"
                : "")
            }
          >
            <p>INFO</p>
            <img src={Down} alt="" className="w-5 h-5" />
            <div
              onMouseEnter={() => setShowInfo(true)}
              onMouseLeave={() => setShowInfo(false)}
              className={
                "absolute top-1/3 left-0 w-32 h-28 bg-black mt-5 flex flex-col  " +
                (showInfo ? "" : "hidden")
              }
            >
              <Link
                to="/info#size-chart"
                className="hover:cursor-pointer py-1 hover:bg-oren font-inter"
              >
                Size Chart
              </Link>
              <Link
                to="/info#price-list"
                className="hover:cursor-pointer py-1 hover:bg-oren font-inter"
              >
                Price List
              </Link>
              <Link
                to="/info#material"
                className="hover:cursor-pointer py-1 hover:bg-oren font-inter"
              >
                Material
              </Link>
            </div>
          </Link>
          <Link
            to="/contact"
            className={
              "text-xl hover:cursor-pointer hover:scale-105 text-white " +
              (location.pathname === "/contact"
                ? "px-4 py-1 border-oren border-b-2"
                : "")
            }
          >
            CONTACT
          </Link>
          <Link
            to="/cart"
            className={
              "text-xl hover:cursor-pointer hover:scale-105 text-white " +
              (location.pathname === "/cart"
                ? "px-4 py-1 border-oren border-b-2"
                : "")
            }
          >
            <img src={Cart} alt="cart" />
          </Link>
        </div>
      </div>
      <div className="md:hidden flex flex-row justify-between items-center font-inter w-full bg-black px-2">
        <Link to="/">o
          <img src={Logo} alt="" className="h-9 w-9" />
        </Link>
        <div className="flex flex-row items-center gap-3">
          <div onClick={() => setShowModal(true)} className="relative">
            <img src={Cart} alt="cart" className="h-7 w-7" />
            <div className="absolute top-0 right-0 bg-oren rounded-full w-4 h-4 text-sm flex justify-center items-center text-white font-inter">
              {cart.length}
            </div>
          </div>
          <svg
            onClick={() => setShowSidebar(true)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7 hover:cursor-pointer text-oren"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </div>
      </div>
      {/* modal */}
      <Modal
        show={showModal}
        size="xl"
        style={{ paddingRight: 0, paddingTop: 0 }}
        popup
        onClose={() => setShowModal(false)}
      >
        <ModalHeader>
          <h3 className="text-xl font-bold">Shoping Cart</h3>
        </ModalHeader>
        <ModalBody>
          <div className="h-[480px] bg-white">
            <div className="flex flex-col items-center h-[80%] overflow-y-auto">
              {/* body */}
              {cart.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center w-full mb-5"
                >
                  <div className="flex flex-row gap-5">
                    <div className="w-36 h-20">
                      <img
                        src={require("../assets/front.png")}
                        alt="checkout"
                        className=""
                      />
                    </div>
                    <div className="">
                      <h1 className="text-2xl font-bold text-left text-black font-inter">
                        {item.name}
                      </h1>
                      {item.isPoduct ? (
                        <>
                          <h1 className="text-xl font-medium text-left text-black font-inter">
                            Color: Black
                          </h1>
                          <h1 className="text-xl font-medium text-left text-black font-inter">
                            Size: 42
                          </h1>
                          <h1 className="text-xl font-medium text-left text-black font-inter">
                            Gender: Man
                          </h1>
                          <h1 className="text-xl font-medium text-left text-black font-inter">
                            Material : Drifit
                          </h1>
                        </>
                      ) : (
                        <>
                          <h1 className="text-xl font-medium text-left text-black font-inter">
                            $ {item.price}
                          </h1>
                          <h1 className="text-xl font-medium text-left text-black font-inter">
                            quantity: {item.quantity}
                          </h1>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      "flex flex-col " + (item.isPoduct ? "" : "hidden")
                    }
                  >
                    <h1 className="text-xl font-medium text-left text-black font-inter">
                      $0.00
                    </h1>
                    <h1 className="text-xl font-medium text-right text-black font-inter">
                      x 1
                    </h1>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full h-[20%] flex flex-col">
              <div className="flex flex-row justify-between items-center border-b-2">
                <h1 className="text-xl font-medium text-left text-black font-inter">
                  Subtotal
                </h1>
                <h1 className="text-xl font-medium text-left text-black font-inter">
                  $ {subtotal}
                </h1>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Link
            to={cart.length === 0 ? null : "/checkout"}
            className="w-full h-10 text-white font-inter bg-oren text-lg rounded-lg flex justify-center items-center"
            onClick={() => setShowModal(false)}
          >
            Checkout
          </Link>
        </ModalFooter>
      </Modal>
      {/* modal */}
      <Modal
        show={showSidebar}
        onClose={() => setShowSidebar(false)}
        style={{
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          height: "100%",
        }}
        className="h-[100%]"
        size={"xl"}
        popup
      >
        <ModalHeader />
        <ModalBody>
          <div className="h-screen bg-white flex flex-col items-center font-inter gap-5">
            <Link to="/" onClick={() => setShowSidebar(false)} className={location.pathname === "/" ? "text-oren" : ""}>
              HOME
            </Link>
            <Link to="/product" onClick={() => setShowSidebar(false)} className={location.pathname === "/product" ? "text-oren" : ""}>
              PRODUCT
            </Link>
            <Link to="/design-your-own" onClick={() => setShowSidebar(false)} className={location.pathname === "/design-your-own" ? "text-oren" : " border-oren border-2 rounded-lg p-1 "}>
              DESIGN YOUR OWN
            </Link>
            <Link to="/wholesale" onClick={() => setShowSidebar(false)} className={location.pathname === "/wholesale" ? "text-oren" : ""}>
              WHOLESALE
            </Link>
            <Link to="/order" onClick={() => setShowSidebar(false)} className={location.pathname === "/order" ? "text-oren" : ""}>
              ORDER FORM
            </Link>
            <Link to="/info" onClick={() => setShowSidebar(false)} className={location.pathname === "/info" ? "text-oren" : ""}>
              INFO
            </Link>
            <Link to="/contact" onClick={() => setShowSidebar(false)} className={location.pathname === "/contact" ? "text-oren" : ""}>
              CONTACT
            </Link>
          </div>
        </ModalBody>
        <ModalFooter />
      </Modal>
    </div>
  );
}

export default Header;
